import React from 'react';
import { graphql } from 'gatsby';

import { useTrackedState } from '../lib/state';

import SubLayout from '../components/SubLayout';
import CovidArticleArea from '../components/CovidArticleArea';

const CovidArticleList01 = ({ data: { allMarkdownRemark } }) => {
  const covid19Articles = allMarkdownRemark.edges
    .filter(edge => !!edge.node.frontmatter.date)
    .map(({ node: { id, excerpt, frontmatter: { date, title, path } } }) => ({
      id,
      excerpt,
      date,
      title,
      path,
    }));
  const { size } = useTrackedState();
  return (
    <SubLayout topTitle="新型コロナ感染症・後遺症の記事">
      <div
        css={`
          margin: 20px;
        `}
      >
        <CovidArticleArea data={covid19Articles} size={size} />
      </div>
    </SubLayout>
  );
};
export default CovidArticleList01;

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { fileAbsolutePath: { regex: "/covid19-articles/" } }
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 500)
          frontmatter {
            date(formatString: "YYYY/MM/DD")
            title
            path
          }
        }
      }
    }
  }
`;
